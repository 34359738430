<template>
    <page-content title="监控信息">
        <a-descriptions :column="1" bordered size="small" style="margin-bottom: 20px">
            <a-descriptions-item label="名称">{{camera.name}}</a-descriptions-item>
            <a-descriptions-item label="直播地址"><p style="width: 600px">{{camera.link}}</p></a-descriptions-item>
            <a-descriptions-item label="添加时间">{{camera.createdAt}}</a-descriptions-item>
        </a-descriptions>

        <d-player v-if="camera.link" class="player" :options="options" ref="player"></d-player>
    </page-content>
</template>
<script>

    import VueDPlayer from 'vue-dplayer'
    import 'vue-dplayer/dist/vue-dplayer.css'

    export default {
        components: {
            'd-player': VueDPlayer,
        },
        data() {
            return {
                camera: {
                    id: "",
                    link: "",
                },
            }
        },
        computed: {
            playerOptions() {
                return {
                    height: '800',
                    sources: [{
                        type: 'application/x-mpegURL',
                        src: this.camera.link
                        // type:'video/mp4',
                        // src:""
                    }],
                    // techOrder: ['flash'],
                    autoplay: true,
                    controls: false,
                    // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-9.jpg'
                }
            },
            options() {
                return {
                    video: {
                        url: this.camera.link,
                        type: 'flv'
                    }
                }
            }
        },

        created() {
            this.camera.id = this.$route.params.id
            // 获取设备详细信息
            this.$get(`web/camera/${this.camera.id}`, {}).then(r => {
                this.camera = r.data;
            });

        },
        methods: {}

    }
</script>
<style lang="less" scoped>
.player{
    border-radius: 5px;
    overflow: hidden;
}
</style>
